export const UNITS_OF_MEASUREMENT_ENUM = {
  HOURS_MINUTES: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU000_HOURS_MINUTES",
    SYMBOL: "hh:mm",
  },
  CUBIC_METERS: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU001_CUBIC_METERS",
    SYMBOL: "m3",
  },
  CUBIC_METERS_HA: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU002_CUBIC_METERS_HA",
    SYMBOL: "m3/Ha",
  },
  LITERS: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU003_LITERS",
    SYMBOL: "l",
  },
  LITERS_HA: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU004_LITERS_HA",
    SYMBOL: "l/Ha",
  },
  MINUTES_SECONDS: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU005_MINUTES_SECONDS",
    SYMBOL: "mm':ss\"",
  },
  HOURS_MINUTES_HA: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU006_HOURS_MINUTES_HA",
    SYMBOL: "hh:mm/Ha",
  },
  MILLIMETERS: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU007_MILIMETERS",
    SYMBOL: "mm",
  },
  CENTILITERS: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU008_CENTILITERS",
    SYMBOL: "cl",
  },
  DECILITERS: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU009_DECILITERS",
    SYMBOL: "dl",
  },
  CUBIC_METERS_HA_T: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU010_CUBIC_METERS_HA_T",
    SYMBOL: "m3/Ha(t)",
  },
  LITERS_CUBIC_METERS: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU011_LITERS_CUBIC_METERS",
    SYMBOL: "l/m3",
  },
  CENTILITERS_LITERS: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU012_CENTILITERS_LITERS",
    SYMBOL: "cl/l",
  },
  PERCENT: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU013_PERCENT",
    SYMBOL: "%",
  },
  CUBIC_METERS_PER_HOUR: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU014_CUBIC_METERS_HOUR",
    SYMBOL: "m3/h",
  },
  LITERS_PER_HOUR: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU015_LITERS_HOUR",
    SYMBOL: "l/h",
  },
  LITERS_PER_SECOND: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU016_LITERS_SECOND",
    SYMBOL: "l/s",
  },
  CUBIC_METERS_SECOND: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU017_CUBIC_METERS_SECOND",
    SYMBOL: "m3/s",
  },
  UNITS: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU018_UNITS",
    SYMBOL: "U",
  },
  KILOWATTS_PER_HOUR: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU021_KILOWATTS_PER_HOUR",
    SYMBOL: "kW/h",
  },
  KILOWATTS_HOUR: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU019_KILOWATTS_HOUR",
    SYMBOL: "kWh",
  },
  LITERS_PER_SQUARE_METER: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU020_LITERS_PER_SQUARE_METER",
    SYMBOL: "l/m2",
  },
  KILOWATT: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU022_KILOWATTS",
    SYMBOL: "Kw",
  },
  WATT_HOUR_SQUARE_METER: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU023_WATT_HOUR_SQUARE_METER",
    SYMBOL: "Wh/m2",
  },
  TEMPERATURE_CELSIUS: {
    TEXT: "AUTOGENERATED.MEASUREMENT_UNITS.MEU024_TEMPERATURE_CELSIUS",
    SYMBOL: "ºCh",
  },
  UNKNOWN: {
    TEXT: "",
    SYMBOL: "n/a",
  },
};
