//@ts-ignore
import { navigateToUrl } from "single-spa";
import { getToken, isLoggedIn } from "../vegga-front-utils";
import jwt_decode from 'jwt-decode';
//@ts-ignore
import { AuthStore, OrganizationStore } from '@vegga/front-store';
import { environment } from "../../environments/environment";

export function IsLogged() {
  return function (_target: any) {
    if (!isLoggedIn()) {
      navigateToUrl('/authentication/sign-in');
    } else {
      if (!localStorage.getItem('auth_store')) {
        navigateToUrl('/authentication/sign-in');
        //TODO - investigate if is possible to make async decorator
        // getToken().then(res => {
        //   console.log(res);
        //   let userInfo: any = jwt_decode(res as string);
        //   fetch(`${environment.API_CORE_ENPOINT}/users/${userInfo.name}/auth`, {
        //     method: 'GET',
        //     headers: { 'Authorization': `Bearer ${res}` }
        //   })
        //     .then(res => res.json())
        //     .then(data => {
        //       console.log(data);
        //       authStore.storeAuth(data);
        //     })
        //     .catch(_error => navigateToUrl('/authentication/sign-in'))
        // })
      } else {
        const authStore = AuthStore.getInstance();
        const organizationStore = OrganizationStore.getInstance()
        organizationStore.storeOrganization(authStore.getDefaultOrganization());
      }
    }
  };
}