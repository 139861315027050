import { UNITS_OF_MEASUREMENT_ENUM } from "../enums/measurementUnits.enum";

interface MEASUREMENT_UNIT {
  id: Number;
  key: String;
  symbol: String;
}

export const MEASUREMENT_UNITS: MEASUREMENT_UNIT[] = [
  {
    id: 0,
    key: UNITS_OF_MEASUREMENT_ENUM.HOURS_MINUTES.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.HOURS_MINUTES.SYMBOL,
  },
  {
    id: 1,
    key: UNITS_OF_MEASUREMENT_ENUM.CUBIC_METERS.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.CUBIC_METERS.SYMBOL,
  },
  {
    id: 2,
    key: UNITS_OF_MEASUREMENT_ENUM.CUBIC_METERS_HA.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.CUBIC_METERS_HA.SYMBOL,
  },
  {
    id: 3,
    key: UNITS_OF_MEASUREMENT_ENUM.MINUTES_SECONDS.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.MINUTES_SECONDS.SYMBOL,
  },
  {
    id: 4,
    key: UNITS_OF_MEASUREMENT_ENUM.HOURS_MINUTES_HA.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.HOURS_MINUTES_HA.SYMBOL,
  },
  {
    id: 5,
    key: UNITS_OF_MEASUREMENT_ENUM.LITERS.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.LITERS.SYMBOL,
  },
  {
    id: 6,
    key: UNITS_OF_MEASUREMENT_ENUM.LITERS_HA.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.LITERS_HA.SYMBOL,
  },
  {
    id: 7,
    key: UNITS_OF_MEASUREMENT_ENUM.CENTILITERS.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.CENTILITERS.SYMBOL,
  },
  {
    id: 8,
    key: UNITS_OF_MEASUREMENT_ENUM.DECILITERS.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.DECILITERS.SYMBOL,
  },
  {
    id: 9,
    key: UNITS_OF_MEASUREMENT_ENUM.MILLIMETERS.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.MILLIMETERS.SYMBOL,
  },
  {
    id: 10,
    key: UNITS_OF_MEASUREMENT_ENUM.CUBIC_METERS_HA_T.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.CUBIC_METERS_HA_T.SYMBOL,
  },
  {
    id: 11,
    key: UNITS_OF_MEASUREMENT_ENUM.LITERS_CUBIC_METERS.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.LITERS_CUBIC_METERS.SYMBOL,
  },
  {
    id: 12,
    key: UNITS_OF_MEASUREMENT_ENUM.CENTILITERS_LITERS.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.CENTILITERS_LITERS.SYMBOL,
  },
  {
    id: 13,
    key: UNITS_OF_MEASUREMENT_ENUM.PERCENT.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.PERCENT.SYMBOL,
  },
  {
    id: 14,
    key: UNITS_OF_MEASUREMENT_ENUM.CUBIC_METERS_PER_HOUR.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.CUBIC_METERS_PER_HOUR.SYMBOL,
  },
  {
    id: 15,
    key: UNITS_OF_MEASUREMENT_ENUM.LITERS_PER_HOUR.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.LITERS_PER_HOUR.SYMBOL,
  },
  {
    id: 16,
    key: UNITS_OF_MEASUREMENT_ENUM.LITERS_PER_SECOND.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.LITERS_PER_SECOND.SYMBOL,
  },
  {
    id: 17,
    key: UNITS_OF_MEASUREMENT_ENUM.CUBIC_METERS_SECOND.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.CUBIC_METERS_SECOND.SYMBOL,
  },
  {
    id: 18,
    key: UNITS_OF_MEASUREMENT_ENUM.UNITS.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.UNITS.SYMBOL,
  },
  {
    id: 19,
    key: UNITS_OF_MEASUREMENT_ENUM.KILOWATTS_HOUR.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.KILOWATTS_HOUR.SYMBOL,
  },
  {
    id: 20,
    key: UNITS_OF_MEASUREMENT_ENUM.LITERS_PER_SQUARE_METER.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.LITERS_PER_SQUARE_METER.SYMBOL,
  },
  {
    id: 21,
    key: UNITS_OF_MEASUREMENT_ENUM.WATT_HOUR_SQUARE_METER.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.WATT_HOUR_SQUARE_METER.SYMBOL,
  },
  {
    id: 22,
    key: UNITS_OF_MEASUREMENT_ENUM.TEMPERATURE_CELSIUS.TEXT,
    symbol: UNITS_OF_MEASUREMENT_ENUM.TEMPERATURE_CELSIUS.SYMBOL,
  },
];
