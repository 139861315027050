import hash from 'hash.js';
import { navigateToUrl } from 'single-spa';
import { environment } from '../../environments/environment';

interface JWTToken {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    token_type: string;
    id_token: string;
    not_before?: number;
    expires_on?: number;
    resource?: string;
    id_token_expires_in?: number;
    profile_info?: string;
    scope?: string;
    refresh_token_expires_in?: number;
}

function getTokenFingerprintKey(type: 'access' | 'refresh' | 'expire_access' | 'expire_refresh') {
    const ua = navigator.userAgent;
    let result;
    switch (type) {
        case 'access':
            result = hash.sha256().update(`${ua}_access_token`).digest('hex');
            break;
        case 'refresh':
            result = hash.sha256().update(`${ua}_refresh_token`).digest('hex');
            break;
        case 'expire_access':
            result = hash.sha256().update(`${ua}_expires_in_access`).digest('hex');
            break;
        case 'expire_refresh':
            result = hash.sha256().update(`${ua}_expires_in_refresh`).digest('hex');
            break;
    }
    return result;
}

export async function getToken() {
    if (new Date().getTime() < getExpireIn('expire_access') || getExpireIn('expire_access') === 0) {
        let accessToken =  await getAccessToken();
        return accessToken;
    } else {
        let refreshAsyncToken = await refreshToken();
        return refreshAsyncToken;
    }
}

async function refreshToken() {
    if(new Date().getTime() < getExpireIn('expire_refresh') || getExpireIn('expire_refresh') === 0 ) {
        const params = new URLSearchParams({
            grant_type: 'refresh_token',
            scope: `openid ${environment.AZURE_CLIENT_ID} offline_access`,
            client_id: environment.AZURE_CLIENT_ID,
            refresh_token: getRefreshToken(),
        })
        const options = {
            method: 'POST',
            body: params,
            headers: {'Content-Type': 'application/x-www-form-urlencoded' }
        }
        
        try {
            let response = await fetch(`${environment.API_LOGIN_ENDPOINT}login`, options);
            if(!response.ok) {
                throw new Error(response.statusText)
            }
            let newToken = await response.json();
            setAccessToken(newToken.access_token);
            setExpireIn(newToken.expires_in, 'expire_access');
            setRefreshToken(newToken.refresh_token);
            setExpireIn(newToken.refresh_token_expires_in, 'expire_refresh');
            return getAccessToken();
        } catch(error) {
            removeToken();
            navigateToUrl('/authentication/sign-in');
        }
    } else {
        removeToken();
        navigateToUrl('/authentication/sign-in');
    }
}

export function removeToken(): void {
    localStorage.removeItem(getTokenFingerprintKey('access'));
    localStorage.removeItem(getTokenFingerprintKey('refresh'));
    localStorage.removeItem(getTokenFingerprintKey('expire_access'));
    localStorage.removeItem(getTokenFingerprintKey('expire_refresh'));
}

export function setToken(value: JWTToken): void {
    setAccessToken(value.access_token);
    setRefreshToken(value.refresh_token);
    setExpireIn(value.expires_in, 'expire_access');
}


export function isLoggedIn(): boolean {
    return !!localStorage.getItem(getTokenFingerprintKey('access'));
}

function setAccessToken(value: string): void {
    localStorage.setItem(getTokenFingerprintKey('access'), value)
}

async function getAccessToken() {
    return new Promise((resolve, reject) => {
        resolve(localStorage.getItem(getTokenFingerprintKey('access')));
    })
}

function setRefreshToken(value: string): void {
    localStorage.setItem(getTokenFingerprintKey('refresh'), value)
}

function getRefreshToken(): string {
    return localStorage.getItem(getTokenFingerprintKey('refresh'));
}

function setExpireIn(value: number, type: 'expire_access' | 'expire_refresh'): void {
    let expiresAccessDate = new Date().getTime();
    // It's a correction of the value of 2 minutes for the time that could have been lost in sending the information between steps
    let correctionValue = (value * 1000) - (120 * 1000);
    expiresAccessDate = expiresAccessDate + correctionValue;
    localStorage.setItem(getTokenFingerprintKey(type), String(expiresAccessDate))
}

function getExpireIn(type: 'expire_access' | 'expire_refresh'): number {
    return localStorage.getItem(getTokenFingerprintKey(type)) ? Number(localStorage.getItem(getTokenFingerprintKey(type))) : 0;
}