import Excel from "exceljs";
import { saveAs } from "file-saver";
import { veggaLogoBase64 } from "./assets";

type FormatToExport = 'xls';

interface ConfigExport {
  fileName: string;
  workSheetName: string;
}

export function exportToDocument(
  format: FormatToExport,
  columns,
  data,
  config: ConfigExport
) {
  if (format === "xls") {
    exportToXLS(columns, data, config);
  }
}

async function exportToXLS(columns, data, config: ConfigExport) {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet(config.workSheetName || "Sheet");
  worksheet.columns = parseToExcelJSColumnDef(columns);
  data.forEach((row) => worksheet.addRow(row));

  customExcel(workbook, worksheet, config);

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: "application/xlsx" });
  saveAs(blob, `VEGGA-${config.fileName || ""}.xlsx`);
}

function parseToExcelJSColumnDef(dataGridColDef) {
  return dataGridColDef.map((col) => {
    return Object.keys(col)
      .filter((key) => {
        return key === "field" || key === "headerName";
      })
      .reduce((res, key) => {
        let result;
        if (key === "field") {
          result = { key: col[key] };
        } else {
          result = { header: col[key] };
        }
        return { ...res, ...result };
      }, {});
  });
}

function customExcel(
  workbook: Excel.Workbook,
  worksheet: Excel.Worksheet,
  config: ConfigExport
) {
  const logo = worksheet.getRow(1);
  logo.height = 40;
  addedVeggaLogo(workbook, worksheet);
  worksheet.insertRow(1, {});
  worksheet.insertRow(2, {});
  worksheet.mergeCells("A1:D1");
  worksheet.mergeCells("H1:P1");
  const emptyRow = worksheet.getRow(2);
  emptyRow.height = 15;
  const header = worksheet.getRow(3);
  header.height = 15;
  header.font = { bold: true };
  setTitleSheet(worksheet, config);
}

function addedVeggaLogo(workbook: Excel.Workbook, worksheet: Excel.Worksheet) {
  const imageId2 = workbook.addImage({
    base64: veggaLogoBase64,
    extension: "png",
  });
  worksheet.addImage(imageId2, "A1:D1");
}

function setTitleSheet(worksheet: Excel.Worksheet, config: ConfigExport) {
  const titleCell = worksheet.getCell("H1");
  titleCell.value = generateFileName(config.fileName);
  titleCell.font = { bold: true, size: 18 };
  titleCell.alignment = { vertical: "middle", horizontal: "center" };
}

function generateFileName(name) {
  let result = name.replaceAll("-", " ");
  const [first, others] = [result.slice(0, 1), result.slice(1)];
  return [first.toUpperCase(), others].join("");
}

/**
 * Transform Buffer/Blob/Binary response to an Excel file
 * and generate the code for download file.
 * @param binaryFile VeggaResponse that contains blob/binary/buffer data
 * @param fileName Excel filename
 */
export const downloadExcel = (binaryFile: Uint8Array, fileName: string) => {
  const byteArray = new Uint8Array(binaryFile);
  const a = window.document.createElement("a");
  a.href = window.URL.createObjectURL(
    new Blob([byteArray], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );
  a.download = `${fileName}.xlsx`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}