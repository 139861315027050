const PREFERENCES = 'preferences';
export enum LocalesEnum {
    ES = 'es-ES',
    EN = 'en-EN',
    PT = 'pt-PT',
    FR = 'fr-FR',
    IT = 'it-IT',
}

/**
 * @deprecated use {@link getAppLocale} instead
 * Gets application language
 * @return Application language
 */
export function getAppLanguage(): string {
    let preferences = JSON.parse(localStorage.getItem(PREFERENCES) || '{}');
    return preferences.appLanguage || 'es';
}

/**
 * @deprecated use {@link setAppLocale}
 * Gets application language
 * @return Application language
 */
export function setAppLanguage(value: string) {
    let preferences = JSON.parse(localStorage.getItem(PREFERENCES) || '{}');
    preferences.appLanguage = value;
    localStorage.setItem(PREFERENCES, JSON.stringify(preferences));

    setAppLocale(`${value}-${value.toUpperCase()}`);
}

/**
 * Gets application locale
 * @return Application locale
 */
export const getAppLocale = (): string => {
    let preferences = JSON.parse(localStorage.getItem(PREFERENCES) || '{}');
    return preferences.locale || LocalesEnum.ES;
}

/**
 * Sets application locale
 * @return Application locale
 */
export const setAppLocale = (value: string) => {
    let preferences = JSON.parse(localStorage.getItem(PREFERENCES) || '{}');
    preferences.locale = value;
    // TODO: Add date, currency and number formats
    localStorage.setItem(PREFERENCES, JSON.stringify(preferences));
}