//@ts-ignore
import { VersioningStore } from '@vegga/front-store';
import axios from 'axios';

export function setMicroVersion() {
    let versions = [];
    //@ts-ignore
    let tokensURL = document.querySelector('[content="scripts-start"]').nextElementSibling.href;
    //@ts-ignore
    let dsURL = document.querySelector('[content="scripts-start"]').nextElementSibling.nextElementSibling.src;
    
    const scriptsRAW = document.querySelector('[content="scripts-end"]').previousElementSibling.innerHTML;
    let micros = JSON.parse(scriptsRAW).imports;

    setVersion(tokensURL.substring(0, tokensURL.lastIndexOf('/')).concat('/version'), '@vegga/design-tokens', versions)
    setVersion(dsURL.replace('/dist/design-system/design-system.esm.js', '/version'), '@vegga/design-system', versions)
    
    Object.entries(micros).forEach(([key, value]: [string, string]) => {
        if(key === '@vegga/front-irrigation-manager') {
            setVersion(value.replace('/js/app.js', '/version'), key, versions)
        } else {
            const versionUrl = value.substring(0, value.lastIndexOf('/')).concat('/version');
            setVersion(versionUrl, key, versions)
        }
      });

    const versionStore = VersioningStore.getInstance();
    versionStore.storeVersion(versions);
}

function setVersion(url: string, key: string, versions: any[]) {
    axios.get(url)
    .then(res => {
        versions.push({name: key, version: res.data});    
    })
    .catch(error => {
        versions.push({name: key, version: 'v0.0.0'});
    })
}
