import { catchError, from, of, switchMap } from "rxjs";
import { fromFetch } from "rxjs/internal/observable/dom/fetch";

export function showLoader() {
  if (!document.querySelector("vegga-loader")) {
    document.body.appendChild(document.createElement("vegga-loader"));
  }
}

export function hideLoader() {
  document.querySelectorAll("vegga-loader").forEach((item) => {
    item.remove();
  });
}

export function getBrowserDevice() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return "mobile";
  } else {
    return "desktop";
  }
}

export function isApplicationUnderMaintenance() {
  const maintenanceURL = document.querySelector('[name="maintenance"]')
    ? document.querySelector('[name="maintenance"]')["content"]
    : undefined;
  return !maintenanceURL
    ? of(null)
    : fromFetch(maintenanceURL).pipe(
        switchMap((response) => {
          if (response.ok) {
            // OK return data
            return response.text();
          } else {
            // Server is returning a status requiring the client to try something else.
            return of({ error: true, message: `Error ${response.status}` });
          }
        }),
        catchError((err) => {
          // Network or other error, handle appropriately
          console.error(err);
          return of({ error: true, message: err.message });
        })
      );
}
